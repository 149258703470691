@import "global";

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: $font-text;
  overflow-x: hidden;
}
h2 {
  margin: 50px 0 0 0;
  padding: 0;
  font-size: 3rem;
  font-family: $font-headline;
  text-align: center;

}
h3 {
  margin: 0;
  padding: 0;
  font-family: $font-subline;
  font-size: 2.5rem;
  text-align: center;
}
h4 {
  padding: 0;
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}
.hidden {
  display: none !important;
}