@import url('https://fonts.googleapis.com/css?family=Parisienne');
@import url('https://fonts.googleapis.com/css?family=Sail');
/** Colors **/
$color-accent: #5F021F;
$color-accent-secondary: #bfbfbf;

$color-white: #fff;
$color-black: #333;

/** Fonts **/
$font-headline: "Sail", sans-serif, cursive;
$font-subline: "Parisienne", Georgia, sans-serif, cursive;
$font-text: "Roboto", sans-serif;

/** Misc **/
$container-size: 1280px;
$breakpoint-L: 600px;
$navigationHeight: 86px;